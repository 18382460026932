// @flow
// core
import React, { useState, useCallback } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

// icons
import InfoIcon from '@material-ui/icons/InfoOutlined';

// components
import { Price } from 'components/Price';

const useStyles = makeStyles((theme) => ({
  childWrap: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  icon: {
    marginLeft: theme.spacing(1),
    fontSize: 14
  },
  popover: {
    pointerEvents: 'none'
  }
}));

export type Props = {
  /**
   * Price without VAT
   */
  value?: number,
  /**
   * Additional details
   */
  additionalDetails: {
    label: ReactNode,
    value: ReactNode
  },
  /**
   * One children which forwards Ref for Popover
   */
  children: React.Node
};

export const PricePopup = ({ value, year, additionalDetails = [], children }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback((event) => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const vat = year >= 2025 ? 23 : 20;

  return (
    <>
      <div
        className={classes.childWrap}
        aria-owns={open ? 'price-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}

        <InfoIcon color="action" className={classes.icon} />
      </div>

      <Popover
        id="price-popover"
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Table size="small">
          <TableBody>
            {typeof value === 'number' && (
              <>
                <TableRow>
                  <TableCell variant="head" component="th" scope="row">
                    Cena s DPH
                  </TableCell>
                  <TableCell align="right">
                    <Price addVat value={value} year={year} />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell variant="head" component="th" scope="row">
                    DPH
                  </TableCell>
                  <TableCell align="right">{vat}%</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell variant="head" component="th" scope="row">
                    Cena bez DPH
                  </TableCell>
                  <TableCell align="right">
                    <Price value={value} />
                  </TableCell>
                </TableRow>
              </>
            )}

            {additionalDetails.map((item, key) => (
              <TableRow key={key}>
                <TableCell variant="head" component="th" scope="row">
                  {item.label}
                </TableCell>
                <TableCell align="right">{item.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Popover>
    </>
  );
};
