// @flow
import React, { forwardRef, Ref } from 'react';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import { NumberString } from 'components/NumberString';
import type { NumberStringProps } from 'components/NumberString';

const useStyles = makeStyles((theme) => ({
  zero: {
    color: theme.palette.text.disabled
  },
  positive: {
    color: theme.palette.success.dark
  },
  negative: {
    color: theme.palette.error.dark
  }
}));

export type Props = {
  /**
   * Ehether to display positive numbers in green
   */
  greenPositive?: boolean,
  /**
   * Whether value is cost - show in red
   */
  isCost?: boolean,
  /**
   * Whether to add VAT to price
   */
  addVat?: boolean
} & NumberStringProps;

export const Price = forwardRef(
  (
    { value, year, greenPositive = false, isCost, addVat, className, ...passingProps }: Props,
    ref: Ref
  ) => {
    const classes = useStyles();

    let adjustedValue = parseFloat(value) || 0;
    adjustedValue = addVat ? value * (year >= 2025 ? 1.23 : 1.2) : value;

    return (
      <span
        {...passingProps}
        ref={ref}
        className={classnames(
          value === 0
            ? classes.zero
            : greenPositive && value > 0
            ? classes.positive
            : isCost || value < 0
            ? classes.negative
            : undefined,
          className
        )}
      >
        <NumberString value={adjustedValue} />
        &nbsp;€
      </span>
    );
  }
);
